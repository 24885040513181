<template>
<CSLayout :type="'qna'">
  <div class="qna-list-header flex flex-col items-center">
    <h3>언제나 어디서나 <span>고객의 편의 향상</span>을 위해 고객의 소리에 귀 기울이겠습니다.</h3>
    <p>
      문의를 남겨주시면,<br/>
      관리자 확인 후에 빠르게 답변드리겠습니다.<br/><br/>
      ※주말, 공휴일의 경우 답변이 늦어질수 있습니다.
    </p>

    <button @click="goToWrite" class="flex justify-center items-center">문의하기</button>
  </div>

  <div class="qna-list-content">
    <div class="qna-list-content-header flex flex-row items-center">
      <div class="flex justify-center items-center">No</div>
      <div class="flex justify-center items-center">제목</div>
      <div class="flex justify-center items-center">작성자</div>
      <div class="flex justify-center items-center">답변</div>
      <div class="flex justify-center items-center">등록일자</div>
    </div>

    <div class="flex flex-row items-center qna-item" v-for="item in qnas" :key="item.id" @click="() => goToDetail(item.id)">
      <div>{{ item.id }}</div>
      <div>{{ item.title }}</div>
      <div>{{ item.hasWriter.name }}</div>
      <div class="flex flex-col items-center confirm">
        <div class="flex justify-center items-center yes" v-if="item.hasQnaComments.length > 0">
          <img src="@/assets/yes.png" />
        </div>
        <div class="flex justify-center items-center no" v-else>
          <img src="@/assets/no.png" />
        </div>
      </div>
      <div>{{ getDate(item.createdAt) }}</div>
    </div>

    <div class="empty" v-if="qnas.length === 0">
      등록된 글이 없습니다.
    </div>
  </div>

  <div class="pagination-wrapper">
    <Pagination
      :currentPage="currentPage"
      :lastPage="lastPage"
      :onPage="onPage" />
  </div>
</CSLayout>
</template>

<script>
import CSLayout from '@/components/CSLayout.vue';
import Pagination from '@/components/Pagination.vue';
import services from '@/services';
import dayjs from 'dayjs';
import {mapState} from 'vuex'

export default {
  name: 'QnaList',
  components: {
    CSLayout,
    Pagination,
  },
  data() {
    return {
      qnas: [],
      currentPage: 1,
      lastPage: 1,
    }
  },
  mounted() {
    this.getQnas();
  },
  computed: {
    ...mapState("user", ["isLoggedIn"]),
  },
  methods: {
    goToWrite() {
      this.$router.push({name: 'QnaWrite'});
    },
    getQnas: async function() {
      const data = await services.qnaList(this.currentPage);
      const {lastPage, data: qnaData} = data;

      this.lastPage = lastPage;
      this.qnas = qnaData;
    },
    onPage: function(value) {
      this.currentPage = value;
      this.getQnas();
    },
    getDate: function(value) {
      if (!value) {
        return '';
      }
      return dayjs(value).format("YYYY.MM.DD");
    },
    goToDetail: function(id) {
      this.$router.push({name: 'QnaDetail', params: {id}})
    }
  },
}
</script>

<style scoped lang="scss">
.qna-list-header {
  margin-bottom: 63px;

  h3 {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -1.2px;
    text-align: center;
    color: #484848;
    margin-bottom: 24px;

    span {
      color: #293bf0;
    }
  }

  p {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.7px;
    text-align: center;
    color: #818181;
    margin-bottom: 34px;
  }

  button {
    width: 264px;
    height: 41px;
    border-radius: 3px;
    background-color: #293bf0;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
  }
}

.qna-list-content {
  border-top: 2px solid #282828;
  margin-bottom: 27px;

  .qna-list-content-header {
    height: 40px;
    border-bottom: 1px solid #d6d6d6;

    div {
      flex: 2;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.96;
      letter-spacing: -0.7px;
      color: #353535;
      
      &:nth-child(1) {
        flex: 1;
      }

      &:nth-child(2) {
        flex: 10;
      }
    }
  }
}

.qna-item {
  height: 44px;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;

  div {
    flex: 2;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.96;
    letter-spacing: -0.7px;
    text-align: center;
    color: #aeaeae;
    
    &:nth-child(1) {
      flex: 1;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.96;
      letter-spacing: -0.7px;
      text-align: center;
      color: #353535;
    }

    &:nth-child(2) {
      flex: 10;

      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.96;
      letter-spacing: -0.7px;
      text-align: left;
      color: #353535;
    }
  }

  .confirm {
    height: 16px;
  }

  .yes {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background-color: #588851;
  }

  .no {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background-color: #424242;
  }
}

.empty {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: -0.7px;
  color: #353535;
  padding: 30px;
  text-align: center;
}

@media (max-width: 639px) {
  .qna-list-header {
    h3 {
      font-size: 15px;
    }
    p {
      font-size: 11px;
    }
  }
  .qna-list-content {
    .qna-list-content-header {
      height: 25px;
      div {
        font-size: 10px;
      }
    }
  }
  .qna-item {
    height: 30px;
    div {
      font-size: 10px;
      &:nth-child(1),
      &:nth-child(2) {
        font-size: 10px;
      }
    }
  }
}
</style>